import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchBusinesses, fetchLocations, fetchPricing } from "../state";
import { Layout, SingleVendor } from "../components";
import { BallBeat } from "react-pure-loaders";
import { AgenciesSection as AddOrUpdateVendorSection, Form } from "../style";
import { navigate } from "gatsby-link";
// import { BallBeat } from "react-pure-loaders";

function AddOrUpdateVendor({
  location = {},
  businessesData,
  fetchBusinesses,
  locationData,
  fetchLocations,
}) {
  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  const [vendor, setVendor] = useState({});
  const vendorIniState = {
    contactPerson: "",
    businessName: "",
    accountTypeId: 0,
    address: "",
    mobileNo: "",
    emailId: "",
    emergencyContactNo: "",
    locationId: "",
    isMyProduct: 1,
    isApi: 0,
    isShowForRetail: 1,
    termsAndConditions: "",
    imageUrl: "",
    advancePercentage: 0,
    isDocuments: 0,
    documentDetails: "",
    drivingLicense: false,
    utilityBill: false,
    // selectedBusiness: "",
    shareholders: [""],
    // type: "",
    // name: "",
    // businessFilter: "all",
    isActive: false,
  };
  var businessesOptions = businessesData
    .filter((business) => {
      return (
        // business.isActive === true &&
        business.isShowForRetail === true && business.isApi === false
      );
    })
    .map((type) => (
      <option value={type.id} key={type.id} label={type.businessName}>
        {" "}
        {type.businessName}{" "}
      </option>
    ));
  var locationOptions = locationData.map((type) => (
    <option value={type.id} key={type.id} label={type.name}>
      {" "}
      {type.name}{" "}
    </option>
  ));
  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    if (businessesData.length === 0) {
      fetchBusinesses();
    }
    if (locationData.length === 0) {
      fetchLocations();
    }
  }, [fetchBusinesses, fetchLocations]);
  return (
    <div>
      <Layout>
        <AddOrUpdateVendorSection>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {vendor.id ? (
              <h1>
                Details of Vendor{" "}
                <span
                  style={{
                    backgroundColor: "yellow",
                  }}
                >
                  {vendor.businessName}
                </span>
              </h1>
            ) : (
              <h1>Add new vendor</h1>
            )}
            <Form
              style={{
                gridTemplateColumns: "none",
                // width: "50%",
                margin: "0rem",
                display: "flex",
              }}
            >
              <label
                style={{
                  color: "black",
                  fontSize: "1rem",
                  marginLeft: "2rem",
                }}
              >
                Select a vendor to edit
                <select
                  style={{ marginTop: "1rem" }}
                  name="business"
                  onChange={(e) => {
                    if (e.target.value === "dummy") return;
                    var vendorData = businessesData.filter(
                      (v) => v.id === Number(e.target.value)
                    );
                    console.log(e.target.value, vendorData);
                    setVendor(vendorData[0]);
                  }}
                >
                  {vendor.businessName ? (
                    <>
                      {" "}
                      <option value={"dummy"} key={vendor.id}>
                        {vendor.businessName}
                      </option>{" "}
                      <option value={"dummy"} key={vendor.businessName}>
                        ---------
                      </option>{" "}
                    </>
                  ) : (
                    <option value={"dummy"} key={0}>
                      Select a vendor
                    </option>
                  )}
                  {businessesOptions}
                </select>
              </label>
              <label style={{ display: vendor.id ? "block" : "none" }}>
                <p
                  style={{
                    // backgroundColor: "rebeccapurple",
                    backgroundColor: "steelblue",
                    cursor: "pointer",
                    width: "fit-content",
                    cursor: "pointer",
                    fontSize: ".6rem",
                    padding: ".7rem",
                    height: "fit-content",
                    fontWeight: "normal",
                    wordSpacing: "2px",
                    marginRight: "1rem",
                    // marginBottom: "3rem",
                    border: "none",
                    float: "right",
                    borderRadius: "1rem",
                    color: "white",
                  }}
                  onClick={() => {
                    setVendor({ ...vendorIniState });
                    // setTimeout(() => {
                    //   navigate("/addorupdatevendor", {
                    //     state: { data: { addVendor: true } },
                    //   });
                    // }, 1000);
                  }}
                  // to="/addorupdatevendor"
                >
                  + Add Vendor
                </p>
              </label>
            </Form>
          </div>

          {businessesData.length !== 0 && locationData.length !== 0 ? (
            <>
              <SingleVendor
                vendorData={vendor}
                businessesOptions={businessesOptions}
                locationsOptions={locationOptions}
                fetchBusinesses={fetchBusinesses}
                locations={locationData}
              />
            </>
          ) : (
            <h3>
              {" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={
                    businessesData.length === 0 && locationData.length === 0
                  }
                />
              </span>{" "}
            </h3>
          )}
        </AddOrUpdateVendorSection>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    businessesData: state.vendors.businesses,
    locationData: state.vendors.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBusinesses: () => dispatch(fetchBusinesses()),
    fetchLocations: () => dispatch(fetchLocations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateVendor);
